@import "../variables.scss";

.single-caroussel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .slides-container {
    width: 90%;
    max-width: 100%;
    height: 0;
    padding-bottom: 60.25%;
    position: relative;
    overflow: hidden;
  }
  .slide {
    border-radius: $border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .slide-hidden {
    display: none;
  }
  .chevron {
    position: absolute;
    font-size: clamp(20px, 3vw, 30px);
    color: $black;
    transition: 0.1s ease-in;
    padding: 1rem;
    &:hover {
      cursor: pointer;
    }
    &.chevron-left {
      left: -1rem;
      transform: translateX(-1rem);
    }
    &.chevron-right {
      right: -1rem;
      transform: translateX(1rem);
    }
  }
  .indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
    .indicator {
      font-size: 0.7rem;
      margin: 0 0.2rem;
      color: $white;
      cursor: pointer;
    }
    .indicator-inactive {
      color: grey;
    }
    @media screen and (max-width: 920px) {
      .indicator {
        font-size: 0.6rem;
      }
    }
    @media screen and (max-width: 640px) {
      .indicator {
        font-size: 0.5rem;
      }
    }
    @media screen and (max-width: 440px) {
      .indicator {
        font-size: 0.4rem;
      }
    }
  }
}
