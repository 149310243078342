@import "../../variables.scss";

.hero-home {
  .hero-banner {
    display: flex;
    align-items: center;
    gap: 30px;

    .hero-text {
      width: 50%;
      h1 {
        font-size: clamp(40px, 6vw, 58px);
      }
      #sub-hero {
        margin: 16px 0 26px;
        font-size: clamp(16px, 3vw, 26px);
      }
      #catch-phrase {
        font-size: clamp(18px, 2.5vw, 24px);
        font-family: $subtitle-font;
      }
    }
    .hero-illustration {
      width: 50%;
      height: 600px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .hero-presentation {
    margin-top: 140px;
    gap: 30px;
    .img-container {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .pres-text {
      width: 50%;
      h4 {
        margin-bottom: 40px;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .hero-home {
    margin-top: 72px;
    .hero-banner {
      flex-direction: column;
      .hero-text {
        width: 100%;
      }
      .hero-illustration {
        width: 100%;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .hero-presentation {
      flex-direction: column;
      .img-container {
        width: 100%;
        order: 2;
      }
      .pres-text {
        width: 100%;
        order: 1;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .hero-home {
    margin-top: 72px;
    .hero-banner {
      flex-direction: column;
      .hero-text {
        width: 100%;
      }
      .hero-illustration {
        width: 100%;
        display: none;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .hero-presentation {
      flex-direction: column;
      .img-container {
        width: 100%;
        order: 1;
      }
      .pres-text {
        width: 100%;
        order: 2;
      }
    }
  }
}

.etablissements {
  .etablissements-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .etablissement-card {
      margin-top: 72px;
      max-width: 420px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 22px 34px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      #papillon {
        width: 100px;
        margin: 0 auto;
      }
      h4 {
        margin: 18px 0 28px;
      }
      p {
        margin-bottom: 42px;
      }
      button {
        margin-left: auto;
        margin-top: auto;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .etablissements {
    .etablissements-container {
      .etablissement-card {
        #papillon {
          width: 80px;
        }
      }
    }
  }
}
.locaux {
  h3 {
    margin-bottom: 72px;
  }
  .locaux-container {
    h4 {
      margin-bottom: 40px;
    }
    .local {
      display: flex;
      gap: 30px;
      .local-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .img-container {
        width: 50%;
        height: 300px;
        iframe {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .locaux-college {
      margin-top: 72px;
    }
  }
}
@media screen and (max-width: 920px) {
  .locaux {
    .locaux-container {
      .local {
        flex-direction: column;
        .local-text {
          width: 100%;
          a {
            margin-top: 24px;
          }
        }
        .img-container {
          width: 85%;
          height: 300px;
          margin: 0 auto;
        }
      }
      .locaux-college {
        .img-container {
          order: 2;
        }
        .local-text {
          order: 1;
        }
      }
    }
  }
}
