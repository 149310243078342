@import "../../variables.scss";

.tarifs {
  .tarifs-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .tarif-card {
      margin: 72px 12px 0 12px;
      max-width: 350px;
      max-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 22px 25px 35px;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      h4 {
        margin-bottom: 26px;
        white-space: wrap;
        @media screen and (max-width: 640px) {
          margin-bottom: 16px;
        }
        &.orange {
          color: $corail;
        }
        &.blue {
          color: $blue;
        }
      }
      p {
        margin-bottom: 16px;
      }
    }
  }
  .non-include-fees {
    margin-top: 72px;
    h4 {
      margin-bottom: 28px;
    }
    li {
      margin-bottom: 16px;
      font-family: $body-font;
      span {
        font-weight: bold;
      }
    }
  }
  .modality {
    margin-top: 72px;
    h4 {
      margin-bottom: 48px;
    }

    p {
      margin-bottom: 42px;
    }
  }
}
