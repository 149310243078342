@import "../variables.scss";

button {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 36px 12px 16px;
  width: fit-content;
  border-radius: $border-radius;
  position: relative;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
    #arrow {
      left: 85%;
      opacity: 100%;
      z-index: 1;
      animation: animArrow 0.2s ease-in-out forwards;
    }
  }
  h5 {
    color: $white;
    white-space: nowrap;
    margin: 0 8px;
  }
  #arrow {
    position: absolute;
    left: 70%;
    opacity: 0;
    z-index: 0;
  }
}

@keyframes animArrow {
  0% {
    left: 70%;
    opacity: 0;
    z-index: 0;
  }
  100% {
    left: 85%;
    opacity: 100%;
    z-index: 1;
  }
}
