@import "../../variables.scss";

.blog-article,
.actuality-article {
  .back {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 72px;
    color: red;
    img {
      transform: rotate(90deg);
      width: 24px;
      margin-right: 8px;
    }
  }
  .article-container {
    .article-header {
      display: flex;
      @media screen and (max-width: 640px) {
        flex-direction: column;
      }
      .article-infos {
        width: 60%;
        margin-right: 12px;
        @media screen and (max-width: 640px) {
          margin-right: 0;
          width: 100%;
        }
        h2 {
          margin-bottom: 42px;
        }
        p {
          margin-bottom: 52px;
        }
      }
      .article-img {
        width: 40%;
        height: 300px;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: $border-radius;
        }
      }
    }
    .article-content {
      margin-top: 72px;
      #intro {
        margin-bottom: 72px;
      }
      p {
        margin: 42px 0;
      }
      #conclusion {
        margin-top: 72px;
      }
    }
    .article-author {
      margin-top: 72px;
      display: flex;
      img {
        width: 40px;
        margin-right: 10px;
      }
      p {
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
}
