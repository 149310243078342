@import "../../variables.scss";
.hero {
  margin-top: 0;
  .hero-presentation {
    display: flex;
    gap: 30px;
    .img-container {
      flex: 50%;
      height: 356px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .hero-infos {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        margin-bottom: 12px;
        line-height: 100%;
      }
      a {
        margin-top: 12px;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .hero {
    .hero-presentation {
      flex-direction: column;
      .img-container {
        flex: 100%;
        order: 2;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .hero-infos {
        p {
          margin: 24px 0;
        }
        width: 100%;
      }
    }
  }
}

.visions-objectifs {
  display: flex;
  gap: 30px;
  .vision-objectifs-container {
    width: 50%;
    h3 {
      margin-bottom: 48px;
    }
    ul {
      li {
        font-family: $body-font;
        list-style-type: square;
        margin-bottom: 22px;
        margin-left: 15px;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .visions-objectifs {
    flex-direction: column;
    align-items: center;

    .vision-objectifs-container {
      width: 80%;
      margin: 0 auto;
      h3 {
        margin-bottom: 48px;
      }
      ul {
        li {
          list-style-type: square;
          margin-bottom: 12px;
          margin-left: 15px;
        }
      }
    }
  }
}
.local {
  h3 {
    margin-bottom: 48px;
  }
  .local-container {
    display: flex;
    gap: 60px;
    p {
      width: 50%;
    }
    .caroussel-container {
      width: 50%;
    }
  }
}
@media screen and (max-width: 920px) {
  .local {
    .local-container {
      flex-direction: column;
      p {
        width: 100%;
      }
      .caroussel-container {
        width: 90%;
        margin: 0 auto;
        height: fit-content;
      }
    }
  }
}
.schedule {
  h3 {
    margin-bottom: 48px;
  }
  .schedule-container {
    width: 90%;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    margin: 48px auto 0 auto;
    padding: 40px;
    .schedule-class {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 36px;
      h4 {
        color: $blue;
      }
      .classe {
        margin-right: 20px;
        background: $corail;
        filter: brightness(0.6);
        color: $white;
        padding: 8px 26px;
        border-radius: $border-radius;
        transition: 0.2s ease-in-out;
        &.active {
          filter: brightness(1);
          transform: scale(1.05);
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
        @media screen and (max-width: 920px) {
          margin-bottom: 12px;
          font-size: 18px;
          .classe {
            padding: 8px 20px;
          }
        }
      }
      @media screen and (max-width: 640px) {
        justify-content: center;
      }
    }
    .schedule-moment {
      margin-bottom: 32px;
      h4 {
        margin-bottom: 12px;
      }
      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
.pedagogic-team {
  h3 {
    margin-bottom: 72px;
  }
}
