@import "../variables.scss";

.contact-container {
  margin-top: 52px;
  display: flex;
  gap: 30px;
  @media screen and (max-width: 1060px) {
    flex-direction: column;
  }
  .contact-info {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1060px) {
      width: 100%;
    }
    .contact {
      margin: 24px 0;
      display: flex;
      flex-direction: column;
      a {
        font-size: clamp(16px, 2vw, 18px);

        font-family: $subtitle-font;
      }
      & a:nth-child(2) {
        margin: 10px 0;
      }
      .social-logos {
        i {
          font-size: 1.5rem;
          color: black;
          margin-right: 20px;
          @media screen and (max-width: 920px) {
            font-size: 1.3rem;
          }
          @media screen and (max-width: 640px) {
            font-size: 1rem;
          }
        }
      }
    }
    .img-container {
      width: 100%;
      height: 356px;
      @media screen and (max-width: 640px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  .contact-form-container {
    width: 50%;
    @media screen and (max-width: 1060px) {
      width: 100%;
    }
    form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 300px;
      column-gap: 30px;
      grid-template-areas:
        "name firstName"
        "email phone"
        "subject subject"
        "message message";
      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
      }
      .name {
        grid-area: name;
      }
      .name {
        grid-area: firstName;
      }
      .email {
        grid-area: email;
      }
      .phone {
        grid-area: phone;
      }
      .subject {
        grid-area: subject;
      }
      .message {
        grid-area: message;
        #text {
          resize: none;
          height: 300px;
        }
      }
      .form-part {
        display: flex;
        flex-direction: column;
        margin-bottom: 42px;
        @media screen and (max-width: 640px) {
          margin-bottom: 24px;
        }
        label {
          font-family: $subtitle-font;
          font-size: clamp(16px, 2vw, 24px);
          margin-bottom: 12px;
        }
        input,
        textarea {
          padding: 8px;
          border: 1px solid $black;
          border-radius: $border-radius;
          height: clamp(35px, 5vw, 50px);
          font-family: $body-font;
          font-size: clamp(14px, 2vw, 18px);
        }
      }
    }
  }
}
