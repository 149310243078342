@import "../../variables.scss";

.add-schedule {
  margin-top: 72px;
  p {
    margin-bottom: 34px;
  }
}

.events-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 72px;
  .events {
    border: 2px solid $black;
    padding: 12px;
    border-radius: $border-radius;
    width: 80%;
    height: 600px;
  }
}
