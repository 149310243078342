@import "../variables.scss";

.cards {
  // margin-top: 122px;
  .dashboard-card {
    padding: 30px;
    width: 85%;
    margin: 0 auto;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin-bottom: 60px;

    h4 {
      margin-bottom: 22px;
    }
    .card-content {
      p {
        margin-bottom: 26px;
      }
    }
  }
}
