@font-face {
  font-family: "general-sans-bold";
  src: url("../assets/fonts/GENERALSANS-BOLD.OTF");
}
@font-face {
  font-family: "general-sans-semibold";
  src: url("../assets/fonts/GENERALSANS-SEMIBOLD.OTF");
}
@font-face {
  font-family: "silka-regular";
  src: url("../assets/fonts/SILKA-REGULAR.OTF");
}
@font-face {
  font-family: "silka-bold";
  src: url("../assets/fonts/SILKA-BOLD-WEBFONT.TTF");
}

$title-font: "general-sans-bold", sans-serif;
$subtitle-font: "general-sans-semibold", sans-serif;
$body-font: "silka-regular", sans-serif;
$body-bold: "silka-bold", sans-serif;

$violet: #853894;
$corail: #f37a6a;
$blue: #5ec3b5;
$black: #000000;
$white: #ffffff;

$box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.25);
$border-radius: 5px;
