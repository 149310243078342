@import "../../variables.scss";

.hero-FAQ {
  h1 {
    margin-bottom: 92px;
  }
  h4 {
    margin-bottom: 24px;
  }
}
.controller {
  .new {
    margin-top: 72px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
  .etablissement {
    display: flex;
    flex-direction: column;
    select {
      width: fit-content;
      margin-top: 12px;
      padding: 4px;
      border-radius: $border-radius;
      font-family: $body-font;
    }
  }
}
.faq-content {
  .controller {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    .fa-sharp {
      &:hover {
        cursor: pointer;
      }
    }
    a {
      font-size: 1rem;
      @media screen and (max-width: 640px) {
        font-size: 0.8rem;
      }
    }
  }
  .section-container {
    margin-bottom: 142px;

    .section-title {
      display: flex;
      flex-direction: column;
      input {
        margin: 12px 0;
        padding: 8px;
      }
      h3 {
        margin-bottom: 34px;
      }
    }
  }
  .question-container {
    margin-top: 12px;
    padding-bottom: 30px;
    border-bottom: 2px solid $black;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 640px) {
      padding-bottom: 18px;
    }
    &:hover {
      cursor: pointer;
    }
    .question {
      display: flex;
      justify-content: space-between;
      img {
        transition: transform 0.15s ease-in-out;
        @media screen and (max-width: 640px) {
          width: 24px;
        }
      }
      &.visible {
        img {
          transform: rotate(180deg);
        }
      }
    }
    .answer {
      max-height: 0;
      overflow: hidden;
      transition-property: max-height;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      margin-top: 12px;

      &.visible {
        max-height: 300px;
      }
    }
    input,
    textarea {
      margin-bottom: 12px;
      padding: 8px;
    }
  }
  button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 12px 8px 12px;
    width: fit-content;
    border-radius: $border-radius;
    background-color: $violet;
    color: $white;
    font-family: $subtitle-font;
    font-size: clamp(14px, 2vw, 18px);
    position: relative;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}
