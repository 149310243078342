@import "../variables.scss";

.avis {
  @media screen and (max-width: 440px) {
    margin: 100px clamp(-30px, -6vw, -60px);
  }
  h3 {
    margin-bottom: 72px;
    margin: 0 clamp(30px, 6vw, 60px) 72px;
  }
  .avis-container {
    max-width: 100%; /* Ajustez la largeur comme vous le souhaitez */
    .avis-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 380px;
      min-height: 200px;
      box-shadow: $box-shadow;
      border-radius: 15px;
      padding: 24px;
      @media screen and (max-width: 920px) {
        width: 330px;
        min-height: 180px;
      }
      @media screen and (max-width: 640px) {
        width: 280px;
        min-height: 180px;
      }
      @media screen and (max-width: 440px) {
        width: 250px;
        min-height: 150px;
      }
      .avis-header {
        display: flex;
        margin-bottom: 24px;
        img {
          width: 30px;
          margin-right: 15px;
        }
      }
      .excerpt-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
      .full-text {
        overflow: unset;
        text-overflow: unset;
        display: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
      }
      #show-more {
        margin-top: 12px;
        text-align: right;
        transition: 0.2s ease-in;
        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }
}
.slick-slider {
  // padding: 20px;
  position: relative;
  .slick-list {
    padding: 10px 10px 10px 0;
  }
}

.slick-dots {
  bottom: -30px; /* Ajustez la position verticale des points indicateurs */
}

.slick-dots li button:before {
  font-size: 12px !important; /* Ajustez la taille des points indicateurs */
  color: #333; /* Ajustez la couleur des points indicateurs */
}
.slick-dots li button:before {
  font-size: 10px;
}
.slick-dots li.slick-active button:before {
  color: #000000 !important; /* Ajustez la couleur du point indicateur actif */
}
.custom-arrow {
  /* Ajoutez des styles communs pour les flèches de navigation */
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
}

.custom-arrow-prev {
  /* Ajoutez des styles spécifiques pour la flèche précédente */
  left: 10px;
  @media screen and (max-width: 440px) {
    left: 10%;
  }
}

.custom-arrow-next {
  /* Ajoutez des styles spécifiques pour la flèche suivante */
  right: 10px;
  @media screen and (max-width: 440px) {
    right: 10%;
  }
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}
