@import "../../variables.scss";

.tags,
.search-tags {
  display: flex;
  flex-wrap: wrap;

  .tag {
    margin-right: 10px;
    margin-top: 12px;
    padding: 10px;
    align-items: center;
    border-radius: $border-radius;

    background: rgba(0, 0, 0, 0.6);
    display: flex;
    transition: transform 0.2s ease;
    &:hover:not(.selected) {
      // transform: scale(1.05);
    }
    &.selected {
      background: rgba(0, 0, 0, 0.8);
    }

    h5 {
      color: $white;
      margin-right: 12px;
      font-family: $body-font;
    }
    p {
      color: $white;
      margin-bottom: 0 !important;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.tags {
  margin-bottom: 6px;
}
