@import "../variables.scss";

footer {
  background: $black;
  color: $white;
  a {
    color: $white;
    h5 {
      color: $white;
    }
  }
  li {
    color: $white;
  }
  p {
    color: $white;
  }
  color: white;
  .main-nav {
    ul {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      margin: 0 20px;
      a {
        margin-top: 20px;
        align-items: center;
        display: flex;
        h5 {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            display: block;
            right: 0;
            width: 100%;
            transform: scaleX(0);
            height: 2.5px;
            transition: transform 0.2s ease; /* Ajouter une transition pour l'animation */
            transform-origin: bottom right;
            background: $white;
          }
          &:hover {
            &:after {
              width: 100%; /* Agrandir la largeur au survol et lorsque actif */
              transform: scaleX(1);
              transform-origin: bottom left;
            }
          }
        }

        img {
          width: 30px;
          @media screen and (max-width: 992px) {
            width: 25px;
          }
          @media screen and (max-width: 640px) {
            width: 20px;
          }
        }
      }
      h5.active {
        &:after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          transform: scaleX(1);
          height: 2.5px;
        }
      }

      a:nth-child(2) {
        margin: 20px 42px 0;
        @media screen and (max-width: 992px) {
          margin: 20px 20px 0;
        }
      }
    }
  }
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    gap: 120px;
    margin: 60px 60px;
    @media screen and (max-width: 992px) {
      justify-content: center;
      gap: 60px;
    }
    @media screen and (max-width: 640px) {
      margin: 40px 40px;
    }
    .secondary-nav {
      width: 50%;

      @media screen and (max-width: 740px) {
        width: 100%;
        ul {
          justify-content: center;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 440px) {
          flex-direction: column;
        }
        a {
          margin: 0 20px 20px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            width: 100%;
            transform: scaleX(0);
            height: 2.5px;
            transition: transform 0.2s ease;
            transform-origin: bottom right;
            background: $white;
          }
          &.active::after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            transform: scaleX(1);
            height: 2.5px;
            width: 100%;
            @media screen and (max-width: 440px) {
              width: 40%;
            }
          }
          @media screen and (min-width: 440px) {
            &:hover {
              &:after {
                width: 100%; /* Agrandir la largeur au survol et lorsque actif */
                transform: scaleX(1);
                transform-origin: bottom left;
              }
            }
          }
        }
      }
    }
    .footer-contact {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 440px) {
        width: 100%;
        margin-left: 20px;
      }
      p,
      a {
        font-family: $subtitle-font;
        margin-bottom: 18px;
        font-size: clamp(14px, 2vw, 18px);
      }
      .social-logos {
        i {
          font-size: 1.5rem;
          color: white;
          margin-right: 20px;
          @media screen and (max-width: 992px) {
            font-size: 1.3rem;
          }
          @media screen and (max-width: 640px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .under-footer {
    border-top: 2px solid $white;
    text-align: center;
    li {
      padding: 4px 0;
      font-family: $body-font;
    }
  }
}
