@import "../../variables.scss";

.teachers-admin {
  margin: 122px 0;
  padding-bottom: 72px;
  .controller-teacher {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    label {
      font-family: $subtitle-font;
      font-size: clamp(16px, 2vw, 20px);
    }
  }
}
.teachers-container-admin {
  margin-top: 72px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .teacher-card {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 16px;
    margin: 26px;
    min-width: 200px;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    .controller {
      width: 80%;
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 0;
      margin: 0 auto;
      margin-bottom: 8px;
    }
    img {
      width: 60px;
      display: block;
      margin: 0 auto;
    }
    input {
      margin: 2px 0;
      border-radius: $border-radius;
    }
    button {
      margin-top: 10px;
    }
    .teacher-info-admin {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .name-class {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
}
