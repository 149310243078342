@import "../../variables.scss";

.add-article {
  margin-top: 122px;
  h2 {
    margin-bottom: 72px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 72px auto;
    .form-part {
      display: flex;
      flex-direction: column;
      margin-bottom: 42px;
      label {
        font-family: $subtitle-font;
        margin-bottom: 10px;
      }
      input {
        font-family: $body-font;
        padding: 14px;
        border: 1px solid $black;
        border-radius: $border-radius;
      }
      textarea {
        font-family: $body-font;
        padding: 14px;
        border: 1px solid $black;
        border-radius: $border-radius;
        height: 200px;
        resize: vertical;
      }
      &.error {
        input,
        textarea {
          border: 2px solid red;
        }
      }
    }
  }
  button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 36px 12px 16px;
    width: fit-content;
    border-radius: $border-radius;
    background-color: $violet;
    color: $white;
    font-family: $subtitle-font;
    font-size: clamp(14px, 2vw, 18px);
    position: relative;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}
