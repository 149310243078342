@import "../../variables.scss";

.avis-content {
  margin-top: 72px;
  .avis {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
    .form-part {
      display: flex;
      flex-direction: column;
      margin-bottom: 42px;
      label {
        font-family: $subtitle-font;
        margin-bottom: 10px;
      }
      input {
        font-family: $body-font;
        padding: 14px;
        border: 1px solid $black;
        border-radius: $border-radius;
      }
      textarea {
        font-family: $body-font;
        padding: 14px;
        border: 1px solid $black;
        border-radius: $border-radius;
        height: 200px;
        resize: vertical;
      }
      select {
        font-family: $body-font;
        width: fit-content;
        padding: 8px;
        border: 1px solid $black;
        border-radius: $border-radius;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
      &.error {
        input,
        textarea,
        select {
          border: 2px solid red;
        }
      }
    }
    a {
      margin-top: 42px;
    }
  }
  .avis-google {
    p {
      margin: 42px 0;
    }
    a {
      button {
        margin: 0 auto;
      }
    }
  }
}
button[type="submit"] {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 36px 12px 16px;
  width: fit-content;
  border-radius: $border-radius;
  background-color: $violet;
  color: $white;
  font-family: $subtitle-font;
  font-size: clamp(14px, 2vw, 18px);
  position: relative;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}
