@import "../../variables.scss";

.teachers-container {
  margin-top: 78px;
  max-width: 100%; /* Ajustez la largeur comme vous le souhaitez */

  .teacher-card {
    width: 250px;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 14px;
    img {
      width: 70px;
      margin: 0 auto 16px auto;
    }
    .name-class {
      margin-bottom: 8px;
      h5 {
        text-align: center;
      }
      p {
        margin-top: 4px;
        font-size: 14px;
      }
    }
    h5 {
      text-align: center;
    }
    p {
      margin-top: 14px;
      text-align: center;
    }
    @media screen and (max-width: 440px) {
      width: 200px;
      img {
        width: 60px;
      }
    }
  }
}
