.legal-content {
  margin-top: 122px;
  h2 {
    margin-top: 72px;
  }
  h3 {
    margin-top: 48px;
    font-size: clamp(20px, 3vw, 30px);
  }
  p {
    margin-top: 24px;
  }
}
