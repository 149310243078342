@import "../../variables.scss";

.article-container-admin {
  .article-header {
    display: flex;

    gap: 30px;
    margin-bottom: 72px;

    .article-infos {
      width: 60%;
      display: flex;
      flex-direction: column;
      .form-part {
        margin-bottom: 42px;
      }
    }
    .article-img {
      width: 40%;
      .img-container {
        width: 100%;
        padding-top: 56.25%; /* Ratio 16:9 (9 / 16 * 100) */
        position: relative; /* Définir une position relative pour que le pseudo-élément fonctionne */
        overflow: hidden; /* Cacher le contenu qui dépasse */
        border-radius: $border-radius;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          border-radius: $border-radius;
        }
      }
    }
  }
  .form-part {
    margin: 42px 0;
    h4 {
      margin-bottom: 16px;
    }
  }
  textarea,
  input {
    width: 100%;
    padding: 8px;
    border-radius: $border-radius;
    border: 1px solid $black;
  }
  .article-author {
    display: flex;
    margin-top: 16px;
    align-items: center;
    margin-top: 72px;
    img {
      width: 40px;
      margin-right: 10px;
    }
    p {
      font-size: 12px;
      margin-top: 6px;
    }
  }
}
@media screen and (max-width: 920px) {
  .article-container-admin {
    .article-header {
      flex-direction: column;

      .article-infos {
        width: 100%;
      }
      .article-img {
        margin-top: 32px;
        width: 100%;
      }
    }
  }
}
