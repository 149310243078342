@import "../variables.scss";

.caroussel-article {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .slides-container {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background-color: rgb(255, 255, 255);

    width: 90%;
    max-width: 90%;
    height: 0;
    padding-bottom: 45.25%;
    position: relative;
    overflow: hidden;
  }
  .slide {
    border-radius: $border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .slide-hidden {
    display: none;
  }
  .chevron {
    position: absolute;
    font-size: clamp(20px, 4vw, 40px);
    color: $black;
    transition: 0.1s ease-in;
    padding: 2rem;
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
    &.chevron-left {
      left: -25px;
      // left: 2rem;
      // transform: translateX(-2rem);
    }
    &.chevron-right {
      right: -25px;
    }
  }
  .indicators {
    display: flex;
    position: absolute;
    bottom: clamp(6px, 2vw, 10px);
    .indicator {
      width: clamp(1.5rem, 5vw, 50px);
      height: clamp(0.5rem, 4vw, 40px);

      margin: 0 0.2rem;
      color: $white;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1px;
      }
    }
    .indicator-inactive {
      filter: brightness(0.5);
    }
  }
}
