@import "variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: $black;
}

h1 {
  font-size: clamp(40px, 7vw, 68px);
  font-family: $title-font;
  line-height: 100%;
}
h2 {
  font-size: clamp(30px, 5vw, 48px);
  font-family: $title-font;
  line-height: 100%;
}
h3 {
  font-size: clamp(24px, 4vw, 38px);
  margin-bottom: clamp(34px, 6vw, 58px);
  font-family: $subtitle-font;
  line-height: 100%;
}
h4 {
  font-size: clamp(22px, 3vw, 28px);
  font-family: $subtitle-font;
  line-height: 100%;
}
h5 {
  font-size: clamp(14px, 2vw, 18px);
  font-family: $subtitle-font;
  line-height: 100%;
}
li {
  font-size: clamp(14px, 2vw, 18px);
  font-family: $subtitle-font;
  line-height: 100%;
}
p {
  font-size: clamp(16px, 2vw, 18px);
  font-family: $body-font;
  span {
    font-weight: bold;
  }
  a {
    font-weight: bold;
    text-decoration: underline;
  }
}
body {
  font-family: $body-font;
}
main {
  margin: 0 clamp(30px, 6vw, 60px);
}

section {
  margin-top: clamp(100px, 20vw, 200px);
}

textarea,
input {
  font-family: $body-font;
}
button {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 36px 12px 16px;
  width: fit-content;
  border-radius: $border-radius;
  background-color: $violet;
  color: $white;
  font-family: $subtitle-font;
  font-size: clamp(14px, 2vw, 18px);
  position: relative;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}
a:hover {
  cursor: pointer;
}

select {
  width: fit-content;
  margin: 12px 6px;
  padding: 4px;
  border-radius: $border-radius;
  font-family: $body-font;
}

main {
  padding: clamp(72px, 12vw, 122px) 0 clamp(200px, 26vw, 260px) 0;
}

.error {
  margin-top: 16px;
  color: red;
}
