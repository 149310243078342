@import "../variables.scss";

.stylizedH1 {
  margin-top: 72px;
  h1 {
    position: relative;
    margin-left: 50px;

    &.orange::before {
      content: "";
      position: absolute;
      top: 20%;
      left: -50px;
      width: 45px; /* Augmentez la largeur selon vos besoins */
      height: 45px; /* Augmentez la hauteur selon vos besoins */
      background-image: url("../../assets/logos/stars/OrangeStar.svg");
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: 920px) {
        top: 15%;
      }
      @media screen and (max-width: 740px) {
        top: 10%;
      }
      @media screen and (max-width: 740px) {
        top: 15%;
        left: -40px;
        height: 35px;
        width: 35px;
      }
    }
    &.blue::before {
      content: "";
      position: absolute;
      top: 20%;
      left: -50px;
      width: 45px; /* Augmentez la largeur selon vos besoins */
      height: 45px; /* Augmentez la hauteur selon vos besoins */
      background-image: url("../../assets/logos/stars/BlueStar.svg");
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: 920px) {
        top: 15%;
      }
      @media screen and (max-width: 740px) {
        top: 10%;
      }
      @media screen and (max-width: 740px) {
        top: 15%;
        left: -40px;
        height: 35px;
        width: 35px;
      }
    }
    &.violet::before {
      content: "";
      position: absolute;
      top: 20%;
      left: -50px;
      width: 45px; /* Augmentez la largeur selon vos besoins */
      height: 45px; /* Augmentez la hauteur selon vos besoins */
      background-image: url("../../assets/logos/stars/VioletStar.svg");
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: 920px) {
        top: 15%;
      }
      @media screen and (max-width: 740px) {
        top: 10%;
      }
      @media screen and (max-width: 740px) {
        top: 15%;
        left: -40px;
        height: 35px;
        width: 35px;
      }
    }
  }
}
