@import "../../variables.scss";

.repas {
  .repas-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .repas-card {
      margin-top: 72px;
      max-width: 500px;
      max-height: 300px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 22px 30px;

      display: flex;
      flex-direction: column;
      p {
        a {
          font-weight: bold;
          text-decoration: underline;
        }
      }
      h4 {
        margin-bottom: 32px;
      }
      button {
        margin-left: auto;
      }
    }
  }
  .traiteur-more-info {
    margin-top: 58px;
    h4 {
      font-size: clamp(18px, 2.5vw, 24px);
      margin-bottom: 12px;
    }
  }
  .collation {
    max-width: 500px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 22px 30px;
    margin-top: 72px;
    margin: 72px auto 0 auto;
    h4 {
      margin-bottom: 22px;
    }
    @media screen and (min-width: 1024px) {
      width: 70%;
    }
  }
}
.garderie {
  .garderie-container {
    margin-top: 72px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
    grid-template-areas:
      "card1 card2"
      "card3 card3";
    .garderie-element {
      h4 {
        margin-bottom: 34px;
      }
    }
    .garderie-element:nth-child(3) {
      margin-top: 34px;
      grid-area: card3;
    }
  }
  .garderie-college-container {
    display: flex;

    .garderie-element {
      margin-top: 72px;
      margin-right: 30px;

      ul {
        margin-top: 24px;
      }
      li {
        font-family: $body-font;
        margin-bottom: 16px;
      }
    }
  }
}

.periscolaire {
  margin-top: clamp(50px, 10vw, 100px);
  .periscolaire-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .children {
      width: 350px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 22px 30px;
      margin-top: 72px;
      h5 {
        margin-bottom: 18px;
      }
      li {
        font-family: $body-font;
        margin-bottom: 12px;
      }
    }
  }
}

.roles-parents {
  .roles-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 42px;
    .role-parent {
      max-width: 330px;
      margin-top: 72px;
      margin: 20px;
      padding: 16px 20px 26px;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      h4 {
        margin-bottom: 34px;
      }
    }
  }
  .role-obligatoire {
    margin-top: 58px;
    h4 {
      margin-bottom: 34px;
    }
  }
}
