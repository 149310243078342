@import "../../variables.scss";

.mail {
  .mail-controller {
    display: flex;
    justify-content: space-between;
    input {
      width: 45%;
      border: 1px solid $black;
      border-radius: $border-radius;
      font-family: $body-font;
      padding: 10px 40px 10px 20px;
      display: flex;
      align-items: center;
      line-height: 100%;
      margin: auto 0;
      font-size: 18px;
    }
  }

  .mail-list {
    margin-top: 122px;
    border-top: 1px solid $black;

    .mail-container {
      .controller {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        a {
          font-size: 1.2rem;
          @media screen and (max-width: 640px) {
            font-size: 1rem;
          }
        }
      }

      display: flex;
      flex-direction: column;
      padding: 34px;
      border-bottom: 1px solid $black;
      .infos-container {
        display: flex;
        gap: 30px;
        margin-bottom: 24px;
        @media screen and (max-width: 640px) {
          flex-direction: column;
        }
      }
      p {
        margin-top: 32px;
      }
      input {
        margin-right: 12px;
        margin-top: 32px;
        padding: 6px;
        border-radius: $border-radius;
        font-family: $body-font;
        font-size: clamp(16px, 2vw, 18px);
      }
      .email-content {
        width: 50%;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
      .child-content {
        width: 50%;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
        .childs {
          display: flex;
          p {
            margin-right: 18px;
          }
          input {
            width: 50%;
            @media screen and (max-width: 640px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
