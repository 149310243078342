@import "../../variables.scss";
// @import "../../shared/button.scss";

.hero-presentation {
  margin-top: 200px;
  @media screen and (max-width: 640px) {
    margin-top: 140px;
  }
  display: flex;
  gap: 30px;
  .img-container {
    flex: 50%;
    height: 356px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .hero-text {
    flex: 50%;
    display: flex;
    flex-direction: column;

    h4 {
      margin-bottom: 26px;
      line-height: 100%;
    }
  }
}
.materials {
  .materials-container {
    display: flex;
    gap: 60px;
    p {
      width: 50%;
    }
    .caroussel-container {
      width: 50%;
    }
  }
}
.professeurs {
  p {
    width: 80%;
  }
}
@media screen and (max-width: 920px) {
  .materials {
    .materials-container {
      flex-direction: column;
      p {
        width: 100%;
      }
      .caroussel-container {
        width: 90%;
        margin: 0 auto;
        height: fit-content;
      }
    }
  }
}
.classes {
  .classes-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .classe-card {
      margin-bottom: 24px;
      box-shadow: $box-shadow;
      display: flex;
      padding: 10px 20px;
      border-radius: $border-radius;
      min-width: 300px;
      min-height: 360px;
      margin: 20px;
      justify-content: space-between;

      flex-direction: column;
      h4 {
        text-align: center;
        margin-bottom: 10px;
      }
      #papillon {
        width: 90px;
        margin: 0 auto;
        margin-bottom: 22px;
      }
      .teacher-list {
        margin-bottom: 24px;
        li {
          margin-bottom: 8px;
          margin-left: 20px;
          list-style-type: disc;
          font-family: $body-font;
        }
      }
      a {
        margin-left: auto;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .classes {
    .classes-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .classe-card {
        min-width: 220px;
        min-height: 280px;

        #papillon {
          width: 80px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .classes {
    .classes-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .classe-card {
        min-width: 240px;
        min-height: 300px;

        #papillon {
          width: 80px;
        }
      }
    }
  }
}
.english {
  .english-container {
    display: flex;
    gap: 30px;
    p {
      width: 50%;
    }
    .img-container {
      flex: 50%;
      height: 356px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .english {
    .english-container {
      flex-direction: column;
      p {
        width: 100%;
      }
      .img-container {
        flex: 50%;
        height: fit-content;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.brevet {
  h4 {
    margin-top: 34px;
  }
}
.avantages {
  .avantages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .avantage-card {
      max-width: 350px;
      margin: 20px;
      padding: 20px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      h4 {
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .avantages {
    .avantages-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .avantage-card {
        max-width: 300px;
        margin: 20px;
        h4 {
          margin-bottom: 20px;
        }
      }
    }
  }
}
