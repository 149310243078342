@import "../../variables.scss";

main {
  margin-top: 122px;
  .back {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 72px;
    color: red;
    img {
      transform: rotate(90deg);
      width: 24px;
      margin-right: 8px;
    }
  }
  .intro {
    margin-top: 72px;
    h4 {
      margin-bottom: 34px;
    }
  }
  .form-faq-section,
  .form-faq-question {
    margin-top: 122px;
    .etablissement-select {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      select {
        width: fit-content;
        margin-top: 12px;
        padding: 4px;
        border-radius: $border-radius;
        font-family: $body-font;
      }
    }
    form {
      select {
        width: fit-content;
        margin: 12px 0;
        padding: 4px;
        border-radius: $border-radius;
        font-family: $body-font;
      }
      div {
        display: flex;
        flex-direction: column;
        input,
        textarea {
          margin-bottom: 24px;
          margin-top: 12px;
          padding: 8px;
          border-radius: $border-radius;
        }
      }
      button {
        margin-top: 24px;
      }
    }
  }
}
