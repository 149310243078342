@import "../variables.scss";

.connexion-container,
.inscription-container {
  width: 90%;

  height: 70vh;
  margin: 72px auto;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  display: flex;
  @media screen and (max-width: 640px) {
    height: 50vh;
    box-shadow: none;
  }
  .img-container {
    width: 50%;
    @media screen and (max-width: 640px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .form-connexion,
  .form-inscription {
    width: 50%;
    padding: 52px 88px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 920px) {
      padding: 22px 48px;
    }
    @media screen and (max-width: 640px) {
      width: 100%;
      padding: 12px 28px;
    }
    h3 {
      margin-bottom: 22px;
    }
    .form-fields {
      margin: 22px 0;
      input {
        width: 100%;
        margin-bottom: 32px;
        border: none;
        border-bottom: 2px solid $black;
        font-family: $body-font;
        font-size: 16px;
        padding: 6px;
      }
    }
  }
}
.inscription-container {
  height: 80vh;
}
