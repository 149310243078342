@import "../variables.scss";

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px clamp(30px, 6vw, 60px);
  position: fixed;
  width: 100%;
  background: $white;
  transition: transform 0.3s ease;
  top: 0;
  z-index: 1;

  &.hide {
    transform: translateY(-100%);
  }
  &.show {
    transform: translateY(0);
  }
  a {
    font-size: 16px;
    font-family: $subtitle-font;
    position: relative;
  }
  &.violet {
    .nav-home {
      a {
        &:after {
          background-color: $violet;
        }
      }
    }

    .nav-links {
      ul {
        a {
          &:after {
            background-color: $violet; /* Couleur du fond lors du survol */
          }
        }
      }
    }
    .responsive-nav {
      ul {
        a {
          &:after {
            background-color: $violet; /* Couleur du fond lors du survol */
          }
        }
      }
    }
  }
  &.corail {
    .nav-home {
      a {
        &:after {
          background-color: $violet;
        }
      }
    }
    .active {
      &:after {
        background-color: $corail;
      }
    }
    .nav-links {
      ul {
        a {
          &:after {
            background-color: $corail; /* Couleur du fond lors du survol */
          }
        }
      }
    }
    .responsive-nav {
      ul {
        a {
          &:after {
            background-color: $corail; /* Couleur du fond lors du survol */
          }
        }
      }
    }
  }
  &.blue {
    .nav-home {
      a {
        &:after {
          background-color: $violet;
        }
      }
    }
    .active {
      &:after {
        background-color: $blue;
      }
    }
    .nav-links {
      ul {
        a {
          &:after {
            background-color: $blue; /* Couleur du fond lors du survol */
          }
        }
      }
    }
    .responsive-nav {
      ul {
        a {
          &:after {
            background-color: $blue; /* Couleur du fond lors du survol */
          }
        }
      }
    }
  }
}
.nav-home {
  a {
    align-items: center;
    display: flex;
    z-index: 2;
    li {
      transition: 0.2s ease-in-out;
      color: $black;
    }
    img {
      width: 30px;
      transition: transform 0.2s ease;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0.4rem;
      right: 0;
      width: 70%;
      transform: scaleX(0);
      height: 2.5px;
      transition: transform 0.2s ease; /* Ajouter une transition pour l'animation */
      transform-origin: bottom right;
    }

    &.active {
      &:after {
        transform: scaleX(1);
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      &:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
  &.active {
    a {
      li {
        color: $white;
      }
    }
  }
}
.nav-links {
  align-items: center;
  @media screen and (max-width: 1120px) {
    transform: translateX(100%);
    transition: 0.3s ease;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: $black;
    color: $white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    ul {
      display: flex;
      flex-direction: column;
      transform: none;

      a {
        margin: 18px clamp(30px, 6vw, 60px);
        position: relative;
        display: inline-block;
        position: relative;
        width: fit-content;
        &:after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: 0;
          width: 70%;
          transform: scaleX(0);
          height: 2.5px;
          transition: transform 0.2s ease; /* Ajouter une transition pour l'animation */
          transform-origin: bottom right;
        }
        &.active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          transform: scaleX(1);
          height: 2.5px;
        }
        li {
          color: $white;
        }
      }
    }
  }
  @media screen and (min-width: 1120px) {
    ul {
      display: flex;

      a {
        margin: 0 20px;
        position: relative;
        display: inline-block;
        &:after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: 0;
          width: 70%;
          transform: scaleX(0);
          height: 2.5px;
          transition: transform 0.2s ease; /* Ajouter une transition pour l'animation */
          transform-origin: bottom right;
        }
        &.active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          transform: scaleX(1);
          height: 2.5px;
        }
        &:hover {
          &:after {
            width: 100%; /* Agrandir la largeur au survol et lorsque actif */
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }
  }
  &.responsive {
    transform: translateX(0%);
  }
}

//RESPONSIVE--------------------------

.toggle-btn {
  display: block;
  position: absolute;
  right: clamp(30px, 6vw, 60px);
  transition: 0.3s;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
  @media screen and (min-width: 1120px) {
    display: none;
  }
  &.active {
    span {
      background-color: $white;

      &:nth-child(1) {
        transform: rotate(45deg) translateY(10px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translateY(-10px);
      }
    }
  }
  span {
    height: 3px;
    width: 30px;
    background-color: $black;
    display: flex;
    margin-top: 5px;
    transition: 0.2s ease;
    &:nth-child(1) {
      width: 30px;
    }
    &:nth-child(2) {
      width: 15px;
      margin-left: auto;
    }
    &:nth-child(3) {
      width: 30px;
    }
  }
}
